<template>
    <div class="inner">
        <div class="block-title-login">
            <div class="group-login-page">
                <div class="item-login-page active">
                    <h3 class="text-left">
                        <a href="javascript:;">{{ $tBnd("update_info") }}</a>
                    </h3>
                </div>
            </div>
        </div>
        <ul class="form-register">
            <li class="custom-input">
                <input type="text" id="ipt-firstname" class="ipt" v-model.trim="userInfo.firstName">
                <label for="ipt-lastname" class="lbl">{{$tBnd('firstName')}} <span class="important">*</span></label>
                <!--<div class="txt-mess ml-0 text-left"
                     v-if="submitted && !$v.userInfo.firstName.required">
                    {{ $tBnd("info_lastname_required") }}
                </div>-->
            </li>
            <li class="custom-input">
                <input type="text" id="ipt-lastname" class="ipt" v-model.trim="userInfo.lastName">
                <label for="ipt-lastname" class="lbl">{{$tBnd('lastName')}} <span class="important">*</span></label>
                <!--<div class="txt-mess ml-0 text-left"
                     v-if="submitted && !$v.userInfo.lastName.required">
                    {{ $tBnd("info_lastname_required") }}
                </div>-->
            </li>
            <li class="custom-input">
                <input-password v-model.trim="userInfo.password" @enter="updateInfo" :label="$tBnd('password')" :requied="true" :showGuide="true"/>
            </li>
            <li class="custom-input">
                <input-password v-model.trim="userInfo.rePassword" @enter="updateInfo" :label="$tBnd('repassword')" :requied="true" />
            </li>
        </ul>
        <span class="text-center txt-date-birth">{{$tBnd('dob')}} <span class="important">*</span></span>
        <date-dropdown v-model="userInfo.birthdate"
                       min="1900"
                       :max="new Date().getFullYear() - 5 + ''"
                       class="m-b-25" />
        <bnd-web-select-ul :options="genderOptions"
                           code="value"
                           label="name"
                           :displayText="$tBnd('info_gender')"
                           v-model="userInfo.gender"
                           :showSearch="false"
                           :isRequired="true"
                           :isSubmitted="submitted" />


        <div class="btn-area">
            <button type="button" class="btn" @click="updateInfo">{{$tBnd("update")}}</button>
        </div>
        <a href="javascript:void(0)" class="txt-choice txt-cancel" @click="skip">{{ $tBnd("skip") }}</a>
    </div>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    export default {
        props: {
            dataUserInfoForRegister: {
                type: Object
            }
        },
        components: {},
        data() {
            return {
                genderOptions: [],
                userInfo: {
                    id: 0,
                    username: '',
                    address: '',
                    birthdate: (new Date().getFullYear() - 5) + '/1/1',
                    gender: null
                },
                otp: '',
                seconds: 0,
                isSendOtp: false,
                submitted: false,
                validations: {
                    userInfo: {
                        firstName: {
                            required,
                        },
                        lastName: {
                            required,
                        },
                        password: {
                            required,
                        },
                        repassword: {
                            required,
                        },
                        gender: {
                            required,
                        }
                    }
                },
            };
        },
        created() {
            this.userInfo = { ...this.dataUserInfoForRegister }
            this.userInfo.birthdate = (new Date().getFullYear() - 5) + '/1/1'
            this.genderOptions = this.appSettings.gender.map((e) => {
                return {
                    value: e.id,
                    name: this.$tBnd(e.name),
                };
            });
        },
        methods: {
            cancelUpdateInfo() {
                this.$router.push('/');
            },
            updateInfo() {
                //this.submitted = true;
                //this.$v.$touch();
                if (!this.userInfo.firstName) {
                    return this.$error(this.$tBnd('error_first_name_empty'));
                }
                if (!this.userInfo.lastName) {
                    return this.$error(this.$tBnd('error_last_name_empty'));
                }
                if (!this.userInfo.password) {
                    return this.$error(this.$tBnd('error_password_empty'));
                } else {
                    var check = this.checkPassword(this.userInfo.password)
                    if (!check) {
                        return this.$error(this.$tBnd('error_password_invalid'));
                    }
                }
                if (!this.userInfo.rePassword) {
                    return this.$error(this.$tBnd('error_repassword_empty'));
                }
                if (this.userInfo.password || this.userInfo.rePassword) {
                    if (this.userInfo.password !== this.userInfo.rePassword)
                        return this.$error(this.$tBnd('error_confirm_new_password'));
                }
                if (!this.userInfo.birthdate) {
                    return this.$error(this.$tBnd('error_birth_date_empty'));
                }
                if (!this.userInfo.gender) {
                    return this.$error(this.$tBnd('error_gender_empty'));
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.userInfo.id ? this.$api.ACCOUNT_UPDATE_USER : this.$api.ACCOUNT_REGISTER, this.userInfo)
                    .then((_) => {
                        $loading.hide();
                        if (typeof (window) != "undefined" && window.flutter_inappwebview) {
                            window.flutter_inappwebview.callHandler('register_success', '');
                            return;
                        }
                        if (this.stepRegister > 0) {
                            //quay lại form đăng nhập
                            this.$emit('completeUpdate')
                        } else {
                            this.$router.push('/');
                        }
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }


                    });
            },
            skip() {
                this.$emit('completeUpdate')
            }
        },
    };
</script>
