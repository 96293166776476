import Vue from 'vue';
import VueRouter from 'vue-router';
import appDefault from '../layouts/default';
import appMananger from '../layouts/manager';
import appEmpty from '../layouts/empty';
import appIframe from '../layouts/iframe';
// import store from '../store/user/store'

const _import = require('./_import_sync');
import { SUPPORTED_LOCALES } from '../locales/supported-locales'

function getLocaleRegex() {
    let reg = ''
    SUPPORTED_LOCALES.forEach((locale, index) => {
        reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`
    })
    return `(${reg})`
}

Vue.use(VueRouter);

const routes = [{
    path: `/:lang${getLocaleRegex()}?`,
    component: appEmpty,
    children: [{
        path: 'account',
        name: 'Account',
        component: appMananger,
        children: [{
            path: '',
            component: _import('account/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'logout',
            component: _import('account/logout'),
            meta: { requiresAuth: true }
        },
        {
            path: 'email',
            component: _import('account/email/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'phone',
            component: _import('account/phone/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'address',
            component: _import('account/address/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'address/:id',
            component: _import('account/address/create'),
            meta: { requiresAuth: true }
        },
        {
            path: 'info',
            component: _import('account/info/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'language',
            component: _import('account/default-language'),
            meta: { requiresAuth: true }
        },
        {
            path: 'change-email',
            component: _import('account/change-email'),
            meta: { requiresAuth: true },
        },
        {
            path: 'change-phone',
            component: _import('account/change-phone'),
            meta: { requiresAuth: true },
        },
        {
            path: 'active-email',
            component: _import('account/active-email'),
            meta: { requiresAuth: true },
        },
        {
            path: 'active-phone',
            component: _import('account/active-phone'),
            meta: { requiresAuth: true },
        },
        {
            path: 'token',
            component: _import('account/token'),
            meta: { requiresAuth: true }
        },
        {
            path: 'profile',
            component: _import('account/profile/profile'),
            meta: { requiresAuth: true }
        },
        {
            path: 'profile/:id',
            component: _import('account/profile/profile-edit'),
            meta: { requiresAuth: true }
        }
        ]
    },
    {
        path: 'security',
        name: 'Security',
        component: appMananger,
        children: [
            {
                path: 'password',
                component: _import('security/change-password'),
                meta: { requiresAuth: true }
            },
            {
                path: 'device',
                component: _import('security/device/index'),
                meta: { requiresAuth: true }
            },
            {
                path: 'device/:id',
                component: _import('security/device/detail'),
                meta: { requiresAuth: true }
            },
            //{
            //    path: '2fa',
            //    component: _import('security/2fa'),
            //    meta: { requiresAuth: true }
            //},
            {
                path: 'recovery-ways',
                component: _import('security/recovery-ways'),
                meta: { requiresAuth: true }
            },
            {
                path: 'delete',
                component: _import('security/delete'),
                meta: { requiresAuth: true }
            },
            {
                path: 'set-password',
                component: _import('security/set-password'),
                meta: { requiresAuth: true }
            },
            {
                path: 'activity',
                component: _import('security/activity'),
                meta: { requiresAuth: true }
            },
            {
                path: 'kyc',
                component: _import('security/kyc'),
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: 'notification',
        name: 'Notification',
        component: appMananger,
        children: [{
            path: '/',
            component: _import('notification/notification-config'),
            meta: { requiresAuth: true }
        }]
    },
    {
        path: 'order',
        name: 'Order',
        component: appMananger,
        children: [{
            path: '/',
            component: _import('order/index'),
            meta: { requiresAuth: true }
        }]
    },
    {
        path: 'point',
        name: 'Point',
        component: appMananger,
        children: [{
            path: '/',
            component: _import('point/point-history'),
            meta: { requiresAuth: true }
        }]
    },
    {
        path: '',
        name: 'Home',
        component: appDefault,
        children: [{
            path: '/',
            component: _import('index')
        }, {
            path: 'error',
            component: _import('oidc/error')
        }, {
            path: 'login',
            component: _import('login')
        }, {
            path: 'callback',
            component: _import('oidc/callback')
        }, {
            path: 'register',
            component: _import('register')
        }, {
            path: 'logout',
            component: _import('oidc/logout')
        },
        {
            path: 'forgot-password',
            component: _import('forgotpassword')
        }, {
            path: 'silent-renew',
            component: _import('oidc/silent-renew')
        }, {
            path: 'captcha',
            component: _import('captcha')
        },
        {
            path: 'error/external-login',
            component: _import('error/external-login')
        }
        ]
    },
    {
        path: '',
        name: 'Home',
        component: appIframe,
        children: [{
            path: 'address',
            props: { iframe: true },
            component: _import('account/address/index'),
        },
        {
            path: 'address/:id',
            props: { iframe: true },
            component: _import('account/address/create'),
        },
        {
            path: 'info',
            props: { iframe: true },
            component: _import('account/info/index'),
        },
        {
            path: 'change-password',
            props: { iframe: true },
            component: _import('security/change-password'),
        },
        {
            path: 'delete-account',
            component: _import('security/delete'),
            props: { iframe: true },
        },
        {
            path: 'app',
            props: { iframe: true },
            component: _import('app'),
        }]
    },
    {
        path: '*',
        component: _import('404'),
    }
    ]
},

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         try {
//             if (this.$cookie.get(this.$cookieConstants.ACCESS_TOKEN))
//                 return next();
//             router.push({ path: '/', query: { returnUrl: to.fullPath }, params: {} });
//         } catch (e) {
//             next();
//         }
//     } else {
//         next();
//     }
// });

export default router;
